
// Welcome Area CSS
.hero-section{
    position: relative;
    min-height: 700px;
    background-size: cover;
    overflow: hidden;
    background: url(../../../assets/img/bg-img/header-1.jpg) no-repeat center top; 
    display: flex;
    justify-content: center;
    align-items: center;
}
.hero-section.fullwidth-header .special-head.dark{
    padding-left: 0
}
.hero-section.fullwidth-header .special-head.dark:before{
    display: none;
}
@media (max-width: 991px){
    .hero-section.fullwidth-header{
        padding-bottom: 285px;
        background-position: 50% 100%;
        background-size: 753px;
    }
}
@media (max-width: 991px){
    .hero-section.fullwidth-header{
        background-position: 50% 100%;
        background-size: 897px;
    }
}

.welcome_area {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 900px !important;
}

.hero-content {
    width: 100%;
    height: 100%;
    background: rgb(11, 89, 49);
    background: -webkit-linear-gradient(to right, rgb(11, 89, 49), rgb(6, 30, 30));
    background: -webkit-linear-gradient(left, rgb(11, 89, 49), rgb(6, 30, 30));
    background: linear-gradient(to right, rgb(11, 89, 49), rgb(6, 30, 30));
    position: absolute !important;
    top: 0;
    left: 0;
    z-index: 10;
}
.hero-content.globe{
    background: linear-gradient(to right, rgb(11, 89, 49), rgb(8, 64, 35));
}
.hero-content.transparent{
    background: transparent;
}
.hero-content .ico-counter{
    margin: 100px 7% 0;
}
@media (max-width: 992px){
    .hero-content .ico-counter,
    .hero-content .service-img-wrapper .image-box{
        margin-top: 0px !important
    }

}
.welcome-content {
    position: relative;
    z-index: 1;
    margin-top: 90px;
}
.special-head{
    color: #28cc8b;
    padding-left: 40px;
    font-size: 18px;
    position: relative;
}
.special-head:before{
    content: '';
    background: #1096d0;
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 2px;
}
.special-head.dark{
    color: #f8f517;
    font-weight: bold;
}
.special-head.dark:before{
    background: #fff
}
.integration-link {
    padding: 8px 10px;
    display: inline-block;
    vertical-align: top;
    position: relative;
    letter-spacing: .58px;
    font-size: 14px;
    color: #333;
    line-height: 24px;
    color: #d5d5ea
}

.integration-link:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background : #eee;
    border-radius: 100px;
}
.integration-icon {
    margin: 0 5px 0 0;
}
.integration-icon .badge{
    background: #14cab1;
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    border-radius: 100px;
    padding: 6px 13px;
}
.integration-text {
    margin: 0 7px 0 0;
    color: #7e7b7b
}
.integration-link>* {
    position: relative;
    z-index: 2;
    display: inline-block;
    vertical-align: top;
}
.header-ilustration-1{
    min-width: 0px;
    margin-top: 60px;
    position: relative;
}
.header-ilustration-1 img{
    width: 100%;
    border-radius: 10px
}
.header-ilustration-1.small img{
    width: 85%
}
.header-ilustration-1.big img{
    width: 105%
}
.has-shadow {
    -webkit-box-shadow: 0 9px 68px 0 rgba(62,57,107,.2);
    box-shadow: 0 9px 68px 0 rgba(62,57,107,.2);
    border-radius: 10px
}

@media (max-width: 992px){
    .header-ilustration-1{
        margin-top: 0
    }
    .bub-right{
        background-size: cover;
    }
    .bub-left{
        background-size: cover;
    }

}

// #about section image
.main-ilustration-6{
    position: relative;
    background: url(../../../assets/img/core-img/about-1.png) no-repeat center 65%;
    height: 100vh;
    background-size: 100%;
}

@media (max-width: 480px){
    .main-ilustration-6,
    .header-ilustration-1{
       display: none;
    }
    .welcome-content {
        vertical-align: middle;
        margin-top: 100px !important

    }
    .main-ilustration-3{
        display: none;
    }
    .welcome-small-wrapper{
       display: none;
    }
    
}

//#hero section header and tagline
.welcome-content h2 {
    font-size: 52px;
    font-weight: 500;
    margin-bottom: 20px;
}
.welcome-content h1 {
    font-size: 100px;
    font-weight: 500;
    margin-bottom: 20px;
}
.welcome-content h1.artficial{
    font-size: 100px
}
.welcome-content p {
    font-family:'poppins';
    font-size: 17px;
    color: #fff;
    margin-bottom: 30px;
} 
.artficial{
    padding-left: 20px;
    border-left: 2px solid #fff;
    position: relative;
    margin-left: -30px;
}
.welcome-content-small{
    margin-bottom: 30px
}
.welcome-content-small h4{
    margin-bottom: 20px
}
.artficial p{
    font-size: 12px
}
.fullscreen-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: -100;
}